// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* CSS Document */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700);

body{font-family: 'Source Sans Pro', sans-serif;margin:0;padding:0;background:#2B1E2C;font-size: 15px;}
a,li,i{transition: all 0.6s;}
.col-spacer{margin-bottom:15px;}

/* LOGIN PAGE */
.login-wrap{margin:15px;}
.login-wrap .logo{text-align:center;margin-bottom:10px;}
.login-wrap .logo img{display:inline;}


/* LoutsCMS HEADER */
.navbar-default{background:#fff;border-color: transparent;box-shadow: 0 0 5px #888}
.navbar-brand{height:auto;}
.navbar-brand img{width:180px;}
.header_right{color:#301B31;padding:0;list-style:none;}
.header_right li{margin-top:10px;margin-left:50px;}
.header_right li.user_image{display:none;margin:0;}
.header_right li.name{margin-left:0;}
.header_right li a{color:#301B31;text-decoration:none;}
.header_right li a i{margin:0 5px 0 3px;color:#301B31;}
.header_right li a:hover i{margin:0 0 0 8px;}

.side-bar{position:absolute;background:#2B1E2C;width:270px;top:75px;left:0;overflow: auto;}
.side-bar ul{margin:0 0 100px 0;padding:0;}
.side-bar ul li {float:none;display:block;}
.side-bar ul li a{color:#AEB7C2;border-bottom: 1px solid rgba(255, 255, 255,0.03);display:block;padding:15px 30px;text-decoration:none;font-size: 16px;}
/* .side-bar ul li a:hover{background:#422C44;} */
.side-bar ul li i {
    margin-right: 10px;
    font-size: 18px;
}
.side-bar ul li a:hover i {color:#00AAFF;}
.side-bar ul li span {position: relative;top: -2px;}


.content{background:#f1f1f1;margin:0;margin-bottom:20px;padding:5px 20px;padding-bottom: 50px;padding-top: 80px;min-height: 700px;}
.content h1{padding-bottom:10px;border-bottom: 1px solid #ddd;margin-bottom: 15px;}

/* DASHBORD PAGE */
.dashboard{list-style:none;padding:0;margin-top:20px;}
.dashboard li a{border: 1px solid #c0c0c0;background:#fff;display:block;color:#333;margin-bottom:30px;}
.dashboard li a i{margin:30px 0;}
.dashboard li a div.dashboard-footer{overflow:hidden;border-top: 1px solid #c0c0c0;padding:10px;background:#e1e1e1;}
.dashboard li a div.dashboard-footer h3{float:left;margin:0;font-size:18px;}
.dashboard li a div.dashboard-footer span{float:right;}


/* LoutsCMS FOOTER */
.footer{position:fixed;bottom:0;width:100%;}
.footer p{background: #f1f1f1;margin:0;padding:5px 15px;border-top:1px solid #ddd;display:block;}


/* 	VIEW PAGE */
.table-view{background:#f1f1f1;}
.table-view table{margin:0;}
.table-view table a{color:#333;text-decoration:none;}
.table-view table a.edit{color:#2B1E2C;}
.table-view table a.edit{color:#179B81;}
.table-view table a.delete{color:#CC334A;}
.action-btn{position:absolute;z-index:1;top:110px;right:15px}
.action-btn a{border-radius:0;}
.fixed{top:80px;position:fixed;box-shadow:0 0 5px #111;border:1px solid #2B1E2C;background:#2B1E2C;padding:10px 15px;right:0;}

.form_wrap label.control-label{font-weight:normal;font-size:16px;}

@media (min-width: 768px) {
    .header_right{float:right;margin-top:30px}
    .header_right li{float: left;}
    .content{margin-left:270px;}
    .footer p{margin-left:270px;}
}

@media (min-width: 970px) {
    .header_right{margin:10px 0 0 0;}
    .header_right li.user_image{display:inline-block;}
    .header_right li.user_image{border-radius:100%;padding:5px;}
    .header_right li.user_image img{width:40px;}
    .header_right li a{margin-left:15px;}
}


.track {
    width: 3px;
    background-clip: padding-box;
    background: #2B1E2C;
    border-style: solid;
    border-color: transparent;
    border-width: 0;
}

.handle {
    width: 5px;
    background-clip: padding-box;
    background:#4c2b4f;
}

.delete{position: absolute;top: 5px; left: 25px;cursor: pointer;background: red;padding: 2px 8px;color: #fff;border-radius: 4px;}
.nav>li>a {color:#333;}

.dash-item .panel-body{padding-top: 40px;padding-bottom: 40px; }
.dash-item .panel-footer{font-size: 22px;font-weight: 300;}
.dash-item a{text-decoration: none;}
.dash-item a i{color: #6d5b65;font-size: 72px;}
.dash-item a:hover i{color: #9F165F}
.dash-item .panel-footer{color: #333;overflow: hidden;}

.loader{}
.spin{
    -webkit-transform-origin: 50% 58%;
    transform-origin:50% 58%;
    -ms-transform-origin:50% 58%; /* IE 9 */
    -webkit-animation: spin .2s infinite linear;
    -moz-animation: spin .2s infinite linear;
    -o-animation: spin .2s infinite linear;
    animation: spin .2s infinite linear;
}

.content-table{background: #fff;padding: 10px; min-height: 500px}
.content-form{background: #fff;padding:20px;padding-top:40px;border: 1px solid #e1e1e1}
.add-new{
    position: relative;
    right: 0;
    top: -57px;
    height: 1px;
    text-align: right;
}